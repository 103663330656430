import { createRouter, createWebHistory } from 'vue-router';
import VueRouteMiddleware from 'vue-route-middleware';
import store from '../store';
import routes from './routes';

const goto = (intendedTo, path = '/login') => ({
  path,
  // save the location we were at to come back later
  query: { redirect: intendedTo.fullPath },
});

const handleRoute = async (route) => {
  // this route requires auth
  if (route.meta.requiresAuth) {
    //check if access token is stored
    const token = store.getters['auth/accessToken'];
    if (token) {
      if (route.name === 'VerifyAccount') return true;
      //verify token validity
      try {
        await store.dispatch('auth/getUser', { forceReload: true, loadAppData: true });
        return true;
      } catch (error) {
        if (error.response?.status === 401) {
          // unathorized, clear access token and redirect to login page.
          store.commit('setToken', null);
          return goto(route);
        }
        if (error.response?.status === 423) {
          // account lokced, redirect to change password.
          return route.name === 'Password' ? true : goto({ fullPath: '/jobs' }, '/password?resetpassword=1');
        }
        //to go login
        return goto(route);
      }
    } else {
      // if not, redirect to login page.
      return goto(route);
    }
  }
};

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
});

router.beforeResolve(handleRoute);

export default router;
