import { getAuth, signInWithPopup, linkWithPopup, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';
import { useToast } from 'vue-toastification';

const toast = useToast();

export const captureRedirectUrl = async ({ commit }) => {
  const url = window.location.href.split('?')[1];
  if (!url) return;
  const query = url.split('=')[0];
  if (query !== 'redirect') return;
  const path = url.split('=')[1]?.replace(process.env.VUE_APP_BASE_URL, '');
  commit('global/setRedirectUrl', path);
};

export const alert = (message, type = 'info', timeout = 3000) => {
  switch (type) {
    case 'info':
      toast.info(message, {
        timeout,
        hideProgressBar: true,
      });
      break;
    case 'success':
      toast.success(message, {
        timeout,
        hideProgressBar: true,
      });
      break;
    case 'error':
      toast.error(message, {
        timeout,
        hideProgressBar: true,
      });
      break;

    default:
      toast.info(message, {
        timeout,
        hideProgressBar: true,
      });
      break;
  }
};

export const saveResumeUrl = ({ commit }, url) => {
  const paths = url?.split('/');
  if (!paths) {
    alert('no resume to display', 'info');
    return;
  }
  const key = paths[paths.length - 1];
  commit('global/setResumeUrl', { key: key.split('?')[0], url });
  const href = `/file/${key}`;
  const a = document.createElement('a');
  a.setAttribute('href', href);
  a.setAttribute('target', '_blank');
  a.click();
};

export const sleep = async (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

export const formatNumber = (amount) => {
  return parseFloat(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const formatTitle = (value) => {
  const result = value.split(' ').join('-');
  return result.toLowerCase().replaceAll('/', '-');
};

export const setCookie = (cname, cvalue, exdays) => {
  try {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    document.cookie = `${cname}=${cvalue};expires=${d.toUTCString()};domain=${process.env.VUE_APP_DOMAIN};path=/;samesite=none`;
  } catch (error) {
    process.env.NODE_ENV === 'development' ? console.log(error) : '';
  }
};

export const getCookie = (cname) => {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const checkStatus = (staus) => {
  return staus === true;
};

export const errorMessage = (error) => {
  if (error?.response) {
    return error.response?.data.message;
  }
  if (error?.request) {
    return error.request.message;
  }
  return error?.message || 'please try again';
};

export const badgeColor = (status) => {
  // if (!['Pending','Reviewed','Interview','Hired','Rejected'].includes(status)) throw error('...')
  return (
    {
      pending: 'bg-[#F6D44D1A] text-[#F6D44D]',
      reviewed: 'bg-[#18D8B61A] text-[#18D8B6]',
      interview: 'bg-[#1EAEFF1A] text-[#1EAEFF]',
      hired: 'bg-[#1EB12D1A] text-[#1EB12D]',
      rejected: 'bg-[#FE06331A] text-[#FE0633]',
    }[status.toLowerCase()] || 'bg-brand-primary text-white'
  );
};

//Google/Github Login  Firebase
export const socialLogin = async (type) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    let provider = type == 'github' ? new GithubAuthProvider() : new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const role = (job) => job.role_title?.replaceAll('/', '-') || job.job.title_name?.replaceAll('/', '-');

export const createJobLink = (job) => {
  const company_name = job.employer.name.toLowerCase().replaceAll(' ', '-');
  const title = role(job)?.toLowerCase().replaceAll(' ', '-');
  const job_link = `/${company_name}/${job.fid}/${title.replaceAll('/', '-')}`;
  return job_link;
};
