<template>
  <div class="font-GTWalsheimPro" id="main">
    <router-view />
    <!-- <broadcasting ref="broadcasting" /> -->
  </div>
</template>

<script setup>
import Broadcasting from './components/Broadcasting.vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { captureRedirectUrl } from '@/utils/helper';

// const broadcasting = ref(null);
const store = useStore();

onMounted(async () => {
  captureRedirectUrl(store);
  
  window.addEventListener(
    'keydown',
    (event) => {
      if (event.defaultPrevented) return; // Do nothing if the event was already processed
      const aForm = document.querySelector('form');
      if (event.key === 'Enter' && aForm) {
        const btn = aForm.querySelector('button');
        if (btn) {
          btn.click();
          // Cancel the default action to avoid it being handled twice
          event.preventDefault();
        }
      }
    },
    true,
  );
});
</script>

<style></style>
