import GlobalService from '../../services/global.service';

export const conversation = {
  namespaced: true,
  state: {
    conversations: [],
    messages: [],
  },
  getters: {
    getSortedConversations(state, getters, rootState, rootGetters) {
      return state.conversations
        .map((item) => {
          const other = item.participants.find((x) => x.participant_identifier !== rootState.auth.userDetails.candidate.participant_identifier);
          return {
            ...item,
            name: other.name,
            profile_url: other.profile_url,
          };
        })
        .sort(function (a, b) {
          return new Date(b.last_message_at) - new Date(a.last_message_at);
        });
    },
    getConversationMessages: (state, getters, rootState, rootGetters) => (conversationId) => {
      return state.messages
        .filter((x) => x.conversation_id === conversationId)
        .map((message) => {
          return {
            ...message,
            left: message.sender_identifier !== rootState.auth.userDetails.candidate.participant_identifier,
          };
        })
        .sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
    },
  },
  mutations: {
    addConversation(state, payload) {
      state.conversations = [...new Map([...state.conversations, payload].map((item) => [item['uuid'], item])).values()];
    },
    setConversations(state, payload) {
      state.conversations = [...new Map(payload.map((item) => [item['uuid'], item])).values()];
    },
    addMessages(state, payload) {
      state.messages = [...new Map([...state.messages, ...payload].map((item) => [item['uuid'], item])).values()];
    },
    addMessage(state, payload) {
      state.messages = [...new Map([...state.messages, payload].map((item) => [item['uuid'], item])).values()];
    },
  },
  actions: {
    async getAllConversations({ commit }, payload) {
      const res = await GlobalService.getAllConversations(payload);
      commit('setConversations', res.data.data);
    },
    async getConversationMessages({ commit }, payload) {
      const res = await GlobalService.getConversationMessage(payload.uuid);
      commit('addMessages', res.data.data);
    },
    async createConversation({ commit }, payload) {
      const res = await GlobalService.createConversation(payload);
      return res.data;
    },
  },
};
