import { axiosInstance } from '@/utils/axios';
class GlobalService {
  async getEnum() {
    return await axiosInstance.get('misc/enums');
  }
  async getJobTitles() {
    return await axiosInstance.get('misc/job-titles');
  }
  async getJobCategories() {
    return await axiosInstance.get('misc/job-categories');
  }
  async getCountries() {
    return await axiosInstance.get('misc/countries');
  }
  async getStates(country) {
    return await axiosInstance.get(`misc/countries/${country}/states`);
  }
  async getCompanyCategory() {
    return await axiosInstance.get('misc/company-categories');
  }
  async getAllJobs(params) {
    return await axiosInstance.get('candidate/job-pools', { params });
  }
  async getSingleJob(id) {
    return await axiosInstance.get(`candidate/job-pools/${id}`);
  }
  async getAllApplications() {
    return await axiosInstance.get('candidate/applications');
  }
  async getSingleApplication(id) {
    return await axiosInstance.get(`candidate/applications/${id}`);
  }
  async applyToJob(data) {
    return await axiosInstance.post(`candidate/job-pools/${data.id}/apply`, data);
  }
  async getAllConversations() {
    return await axiosInstance.get('candidate/conversations');
  }
  async getNotifications(type) {
    return await axiosInstance.get(`candidate/notifications?type=${type}`);
  }
  async getConversationMessage(id) {
    return await axiosInstance.get(`candidate/conversations/${id}/messages`);
  }
  async createConversation(payload) {
    return await axiosInstance.post(`candidate/conversations`, payload);
  }
}
export default new GlobalService();
