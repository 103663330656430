import { setAuthHeaders } from '@/utils/axios';
import AuthService from '../../services/auth.service';
import { checkStatus, setCookie } from '@/utils/helper';

// const user = JSON.parse(localStorage.getItem('user'));
// const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: {
    userDetails: null,
    token: null,
  },
  mutations: {
    setToken(state, payload) {
      localStorage.setItem('token', payload);
      state.token = payload;
      setCookie('tokenCookie', payload, payload ? 30 : 1);
    },
    registerSuccess(state, payload) {
      state.userDetails = payload;
    },
    loginSuccess(state, payload) {
      state.userDetails = payload;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
  actions: {
    async login({ commit }, credential) {
      const res = await AuthService.login(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      const { user, authorisation } = res.data.data;
      commit('loginSuccess', user);
      commit('setToken', authorisation.token);
      localStorage.setItem('token', authorisation.token);
      return { user, message: res.data.message };
    },
    async register({ commit }, credential) {
      const res = await AuthService.register(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      commit('registerSuccess', res.data.data.user);
      commit('setToken', res.data.data.authorisation.token);
      localStorage.setItem('token', res.data.data.authorisation.token);
      return res;
    },
    async linkedAccessToken({ commit }, credential) {
      const res = await AuthService.linkedAccessToken(credential);
      return res;
    },
    async firebaseAccessToken({ commit }, credential) {
      const res = await AuthService.firebaseAccessToken(credential);
      return res;
    },
    async requestOtp({ commit }, payload) {
      const res = await AuthService.requestOtp(payload);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res;
    },
    async verifyOtp({ commit }, credential) {
      const res = await AuthService.verifyOtp(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res;
    },
    async forgetPasswordRequestOtp({ commit }, credential) {
      const res = await AuthService.forgetPasswordRequestOtp(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res;
    },
    async forgetPasswordVerifyOtp({ commit }, credential) {
      const { data } = await AuthService.forgetPasswordVerifyOtp(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return data;
    },
    async forgetPasswordReset({ commit }, credential) {
      const res = await AuthService.forgetPasswordReset(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res;
    },
    async resetPassword({ commit }, credential) {
      const res = await AuthService.resetPassword(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res;
    },
    async getUser({ commit, state }, credential) {
      if (credential.forceReload) {
        const res = await AuthService.getUser();
        //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
        commit('loginSuccess', res.data.data);
      }
      return state.userDetails;
    },
    async updateUserProfile({ commit }, credential) {
      const res = await AuthService.updateUserProfile(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res.data;
    },
    async updateUserPreference({ commit }, credential) {
      const res = await AuthService.updateUserPreference(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res.data;
    },
    async updateUserResume({ commit }, credential) {
      const res = await AuthService.updateUserResume(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      return res.data;
    },
    async onboardProfile({ commit }, credential) {
      return await AuthService.onboardProfile(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      //return res;
    },
    async onboardPreference({ commit }, credential) {
      return await AuthService.onboardPreference(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      //return res;
    },
    async onboardResume({ commit }, credential) {
      return await AuthService.onboardResume(credential);
      //if (!checkStatus(res.data.status)) throw Error(res.data.err_message);
      //return res;
    },
  },

  getters: {
    userDetails: ({ userDetails }) => userDetails,
    accessToken: ({ token }) => token,
  },
};
