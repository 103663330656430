import GlobalService from '../../services/global.service';

export const notification = {
  namespaced: true,
  state: {
    notificationCount: [],
    jobNotificationCount: 0,
    messageNotificationCount: 0,
  },
  getters: {
    getNotificationCount: (state) => {
      return state.notificationCount;
    },
    getJobNotificationCount: ({ jobNotificationCount }) => jobNotificationCount,
    getMessageNotificationCount: ({ messageNotificationCount }) => messageNotificationCount,
  },
  mutations: {
    setNotificationCount(state, payload) {
      state.notificationCount = payload;
    },
    setJobNotificationCount(state, payload) {
      state.jobNotificationCount = payload;
    },
    setMessageNotificationCount(state, payload) {
      state.messageNotificationCount = payload;
    },
  },
  actions: {
    async fetchNotificationCount({ commit }, payload) {
      GlobalService.getNotifications('job').then(({ data }) => {
        commit('setJobNotificationCount', data);
      });
      const { data } = await GlobalService.getNotifications('message');
      commit('setMessageNotificationCount', data);
    },
  },
};
